import React, { createContext, useReducer } from "react";

const initialState = {
  menu: false
};

export const Context = createContext(initialState)

const ACTIONS = { SET_MENU: "set_menu" }

export const ContextReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_MENU:
      return { ...state, menu: action.payload }
    default:
      return state;
  }
}

export const ContextProvider = ({ children }) => {

  const [state, dispatch] = useReducer(ContextReducer, initialState);

  function setMenu(menu) {
    dispatch({
      type: ACTIONS.SET_MENU,
      payload: menu
    })
  }

  const value = { menu: state.menu, setMenu }

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )

}