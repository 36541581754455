const data = [
  {
    id: 1,
    img: "circle_09.png",
    title: "Trottinette électrique",
    price: "300",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 2,
    img: "circle_11.png",
    title: "Rasoir Braun s3",
    price: "74",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 3,
    img: "circle_14.png",
    title: "Lego Navire de recherche",
    price: "98,80",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 4,
    img: "circle_18.png",
    title: "Mannesmann boite à outils",
    price: "86,70",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 5,
    img: "circle_16.png",
    title: "Berjuan Reborn",
    price: "101",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 6,
    img: "circle_22.png",
    title: "Bon restaurant Le Galta",
    price: "60",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 7,
    img: "circle_04.png",
    title: "Vignette 2024",
    price: "40",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 8,
    img: "circle_15.png",
    title: "Aspirateur roomba",
    price: "300",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 9,
    img: "circle_07.png",
    title: "Vreneli en or",
    price: "360",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 10,
    img: "circle_01.png",
    title: "Airpods",
    price: "173",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 11,
    img: "circle_02.png",
    title: "Repas au casino du lac",
    price: "100",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 12,
    img: "circle_06.png",
    title: "Bon cadeau Bain-Bleu",
    price: "99",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 13,
    img: "circle_03.png",
    title: "Bon de change de 100 CHF",
    price: "100",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 14,
    img: "circle_20.png",
    title: "Machine à café Nespresso",
    price: "200",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 15,
    img: "circle_21.png",
    title: "Bon cadeau foot Locker",
    price: "100",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 16,
    img: "circle_08.png",
    title: "Chèque-Cadeau sortie cinéma pour 2",
    price: "50",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 17,
    img: "circle_24.png",
    title: "Trottinette électrique",
    price: "300",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 18,
    img: "circle_12.png",
    title: "Montre sport garmin",
    price: "138",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 19,
    img: "circle_23.png",
    title: "Repas casino du lac",
    price: "100",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 20,
    img: "circle_10.png",
    title: "Moulinex Companion Xl",
    price: "525",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 21,
    img: "circle_17.png",
    title: "iPhone 15",
    price: "849",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 22,
    img: "circle_13.png",
    title: "Playstation 5 digital",
    price: "475",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
  {
    id: 23,
    img: "circle_05.png",
    title: "1 Semaine de vacances à Marrakech",
    price: "3000",
    sponsor: {
      status: true,
      logo: "charter.gif",
      text: "Charter Voyage",
    },
  },
  {
    id: 24,
    img: "circle_19.png",
    title: "Tudor Ranger Acier",
    price: "3000",
    sponsor: {
      status: false,
      logo: "",
      text: "",
    },
  },
];

export default data;
