import React, { useContext } from "react";
import "./Menu.css";
import { Context } from "../Context";
import { IconLogin, IconKey, IconChevronDown } from "@tabler/icons-react";

export default function Menu() {
  const { menu, setMenu } = useContext(Context);

  return (
    <div id="bens-menu-mobile" className={menu ? "" : "is-hidden"}>
      <div
        id="mb-shadow"
        className={menu ? "" : "is-hidden"}
        onClick={() => {
          setMenu(!menu);
        }}
      />
      <div id="mb-wrapper" className={menu ? "" : "is-hidden"}>
        <div className="mb-row">
          <a href="/" className="bens-logo">
            <img
              src="https://bens-digital-change.com/images/logo/bens_digital_change.svg"
              alt="Ben S. Logo"
              width={132}
              height={64}
              loading="lazy"
            />
          </a>
          <div className="swiss-made">
            <img
              src="https://bens-digital-change.com/images/v2/swiss_made_new.webp"
              alt="Swiss Made"
              width={243}
              height={256}
              loading="lazy"
            />
          </div>
        </div>

        <div className="mb-section mb-account">
          <div className="mb-item">
            <a
              href="https://bens-digital-change.swiss/register"
              className="mb-item-label"
            >
              <span>Inscription</span>
              <IconKey />
            </a>
          </div>
          <div className="mb-item">
            <a
              href="https://bens-digital-change.swiss/"
              className="mb-item-label"
            >
              <span>Connexion</span>
              <IconLogin />
            </a>
          </div>
        </div>

        <div className="mb-section mb-secondary mb-download">
          <div className="mb-item mb-has-list">
            <div className="mb-item-label">
              <span>Télécharger</span>
              <IconChevronDown />
            </div>
            <div className="mb-item-list is-show has-store-badge">
              <a
                href="https://apps.apple.com/us/app/keynote/id1587182131"
                target="_blank"
                rel="noopener noreferrer"
                className="app-ios"
              >
                <img
                  src="https://bens-digital-change.com/images/v2/store-custom-ios.webp"
                  alt="IOS"
                  loading="lazy"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=ch.ischange.digital_change_app.bens"
                target="_blank"
                rel="noopener noreferrer"
                className="app-android"
              >
                <img
                  src="https://bens-digital-change.com/images/v2/store-custom-android.webp"
                  alt="Android"
                  loading="lazy"
                />
              </a>
              <a
                href="https://bens-digital-change.swiss/"
                target="_blank"
                rel="noopener noreferrer"
                className="app-web"
              >
                <img
                  src="https://bens-digital-change.com/images/v2/store-custom-web.webp"
                  alt="Web"
                  loading="lazy"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
