import React from 'react';
import "./TrustpilotCarousel.css";


export default function TrustpilotCarousel() {
  return (
    <div id="bens-section-06">
      {/* TrustBox widget - Carousel */}
      <div
        className="trustpilot-widget is-carousel"
        data-locale="fr-FR"
        data-template-id="53aa8912dec7e10d38f59f36"
        data-businessunit-id="624471da9077911b7629b8db"
        data-style-height="140px"
        data-style-width="100%"
        data-theme="light"
        data-stars={5}
        data-review-languages="fr"
      >
        <a
          href="https://fr.trustpilot.com/review/bens-digital-change.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
        {/* End TrustBox widget */}
      </div>
    </div>
  )
}
