import React, { useState } from "react";
import "./Hero.css";
import Calculator from "../components/Calculator";
import TrustpilotWidget from "../components/TrustpilotWidget";
import CurrentTheme from "../components/CurrentTheme";
import YouTube from "react-youtube";
import { useEffect } from "react";
import { IconLoader } from "@tabler/icons-react";

export default function Hero() {
  const initPromoCodes = [
    {
      code: "CAL50",
      value:
        "50 CHF offerts sur votre première opération de change digital d’un montant égal ou supérieur à 1000 CHF jusqu’au",
      date: "31/12/23",
    },
    {
      code: "BDCPROM1",
      value:
        "50 CHF offerts sur votre première opération de change digital d’un montant égal ou supérieur à 1000 CHF jusqu’au",
      date: "31/12/23",
    },
    {
      code: "BDCPROM2",
      value:
        "50 CHF offerts sur votre première opération de change digital d’un montant égal ou supérieur à 500 CHF jusqu’au",
      date: "31/01/24",
    },
  ];

  const [visible, setVisible] = useState(false);
  const [showSocial, setShowSocial] = useState(false);
  const [promoCode, setPromoCode] = useState("CAL50");
  // eslint-disable-next-line
  const [promoCodes, setPromoCodes] = useState(initPromoCodes);
  const [wait, setWait] = useState(true);

  const handleBtnOpen = () => {
    setVisible(true);
  };

  const handleBtnClose = () => {
    setVisible(false);
  };

  const handleWrapper = () => {
    setVisible(false);
  };

  const onPlayerReady = (event) => {
    event.target.playVideo();
  };
  const destroyVideo = (event) => {
    event.target.destroyVideo();
  };

  useEffect(() => {
    if (window) {
      const queryParams = new URLSearchParams(window.location.search);
      const paramName = "promo";

      if (queryParams.has(paramName)) {
        const paramValue = queryParams.get(paramName);
        promoCodes.some((p) => p.code === paramValue)
          ? setPromoCode(paramValue)
          : setPromoCode("CAL50");
      }

      setWait(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div id="bens-hero">
      <div className="bens-container">
        {process.env.REACT_APP_HAS_PROMOTION === "true" ? (
          <div className="hero-col md-reverse">
            <div className="hero-col-box is-bigger">
              <div className="hero-col-box-content">
                <h1>{"Participez à notre grand Calendrier de l’Avent"}</h1>
              </div>
              <div className="hero-col-box-content has-image">
                <img
                  src="/img/gift-box.png"
                  alt="Bens Gift"
                  className="bens-img"
                  loading="lazy"
                  width={64}
                  height={64}
                />
                <h3>{"Plus de 10’000 CHF de cadeaux à gagner!"}</h3>
              </div>
            </div>
            <div className="hero-col-box is-small has-promotion">
              <div className="is-promotion">
                <h6>
                  {promoCodes.find((p) => p.code === promoCode)?.value}{" "}
                  {promoCodes.find((p) => p.code === promoCode)?.date}
                </h6>
                <p className="is-bigger">{"avec le code promo"}</p>
                <span>{wait ? <IconLoader /> : promoCode}</span>
                <div className="hero-action">
                  <a
                    href="https://bens-digital-change.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {"Je profite de l’offre"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="hero-col">
            <div className="hero-col-box has-not-promotion">
              <h1>
                {
                  "Changez facilement vos Francs Suisses en Euros Grâce à notre plateforme en ligne à un taux très attractif"
                }
              </h1>
            </div>
          </div>
        )}

        <div className="hero-col">
          {process.env.REACT_APP_THEME === "default" ? (
            <div className="hero-col-box">
              <div className="hero-box-container">
                <div className="hero-row">
                  <h2 className="hero-title">
                    {"Une solution de change qui répond à vos objectifs personnels"}
                  </h2>
                  <div className="hero-video">
                    <h3 className="video-title">{"Comment fonctionne notre service :"}</h3>
                    <div id="video-action" onClick={handleBtnOpen}>
                      <img
                        src="https://bens-digital-change.com/images/v2/hero-video-capt.webp"
                        alt="Ben S. Digital Change"
                        className="caption-img"
                        width={331}
                        height={185}
                        loading="lazy"
                      />
                    </div>
                    {visible ? (
                      <div
                        id="video-wrapper"
                        onClick={handleWrapper}
                        className={visible ? "is-show" : ""}
                      >
                        <YouTube
                          id="iframe"
                          videoId="p6_RdMZBgO8"
                          opts={{
                            playsinline: 0,
                            autoplay: 1,
                            origin: window.location.origin,
                          }}
                          onReady={onPlayerReady}
                          onEnd={destroyVideo}
                        />
                        <div id="video-close-btn" onClick={handleBtnClose}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                          </svg>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="hero-partner">
                    <h5 className="partner-title">La Solution multicanale avec</h5>
                    <img
                      className="partner-img"
                      src="https://bens-digital-change.com/images/v2/bsc-logo.webp"
                      alt="Ben S. Shop Change"
                      width={100}
                      height={67}
                      loading="lazy"
                    />
                  </div>
                </div>

                <div className="hero-row">
                  <img
                    className="hero-bens-img"
                    src="https://bens-digital-change.com/images/v2/char_bens.webp"
                    alt="The Ben S."
                    width={245}
                    height={556}
                    loading="lazy"
                  />
                </div>
              </div>

              <div className="hero-action">
                <a
                  href="https://bens-digital-change.swiss/register"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  REJOINDRE
                </a>
              </div>
            </div>
          ) : (
            <div
              className="hero-col-box has-cover"
              style={{
                backgroundImage: "url(" + CurrentTheme(process.env.REACT_APP_THEME)["cover"] + ")",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                className="has-cover-filter"
                style={{
                  background:
                    "linear-gradient(135deg, " +
                    CurrentTheme(process.env.REACT_APP_THEME)["color"][0] +
                    " 0%, " +
                    CurrentTheme(process.env.REACT_APP_THEME)["color"][1] +
                    " 100%)",
                }}
              ></div>

              <div className="hero-box-container">
                <div className="hero-row">
                  <img
                    src={CurrentTheme(process.env.REACT_APP_THEME)["cover"]}
                    alt="Cover"
                    className="hero-media"
                    loading="lazy"
                    width={1170}
                    height={629}
                  />
                </div>
              </div>

              <div className="hero-action">
                <a
                  href="/"
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowSocial(!showSocial);
                  }}
                >
                  {"Je tente ma chance"}
                </a>
              </div>

              {showSocial ? (
                <div className="promo-social-links on-hero">
                  <a
                    href="https://www.facebook.com/BenS.Change.Shop"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-social-icon"
                  >
                    <img
                      src="https://bens-digital-change.com/images/icons/facebook.svg"
                      alt="Facebook"
                      width={48}
                      height={48}
                      loading="lazy"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/bens.solutions.de.change"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-social-icon"
                  >
                    <img
                      src="https://bens-digital-change.com/images/icons/instagram.svg"
                      alt="Instagram"
                      width={48}
                      height={48}
                      loading="lazy"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/ben-s-solutions-de-change"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-social-icon"
                  >
                    <img
                      src="https://bens-digital-change.com/images/icons/linkedin.svg"
                      alt="Linkedin"
                      width={48}
                      height={48}
                      loading="lazy"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UC3S8yVGmtgOStQzQrZh8fIg"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-social-icon"
                  >
                    <img
                      src="https://bens-digital-change.com/images/icons/youtube.svg"
                      alt="Youtube"
                      width={48}
                      height={48}
                      loading="lazy"
                    />
                  </a>
                  <a
                    href="https://www.tiktok.com/@bens.solutions.de.change"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-social-icon"
                  >
                    <img
                      src="https://bens-digital-change.com/images/icons/tiktok.svg"
                      alt="TikTok"
                      width={48}
                      height={48}
                      loading="lazy"
                    />
                  </a>
                </div>
              ) : null}
            </div>
          )}

          <div className="hero-col-box is-small has-calculator">
            <Calculator></Calculator>
            <TrustpilotWidget></TrustpilotWidget>
            <a
              href="https://bens-digital-change.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hero-action-btn is-red"
            >
              {"En savoir plus"}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
