import React from "react";

export default function TrustpilotWidget() {
  return (
    <div
      className="trustpilot-widget section-06"
      data-locale="fr-FR"
      data-template-id="5419b732fbfb950b10de65e5"
      data-businessunit-id="624471da9077911b7629b8db"
      data-style-height="20px"
      data-style-width="100%"
      data-theme="light"
    >
      <a
        href="https://fr.trustpilot.com/review/bens-digital-change.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  )
}