import "./App.css";
import Header from "./utils/Header";
import Menu from "./utils/Menu";
import Hero from "./utils/Hero";
import Footer from "./utils/Footer";
import Solutions from "./components/Solutions";
import Bens from "./components/Bens";
import CurrentTheme from "./components/CurrentTheme";
import { ContextProvider } from "./Context";
import Snowfall from "react-snowfall";
import BensBottom from "./components/BensBottom";

function App() {
  return (
    <ContextProvider>
      <div
        id="app"
        style={{
          background:
            "linear-gradient(180deg, " +
            CurrentTheme(process.env.REACT_APP_THEME)["color"][0] +
            " 0%, " +
            CurrentTheme(process.env.REACT_APP_THEME)["color"][1] +
            " 16%)",
        }}
      >
        <Snowfall
          id="snowfall"
          snowflakeCount={50}
          radius={[0.5, 5]}
          color="#ffffff"
          style={{
            position: "fixed",
            zIndex: "1000",
            width: "100vw",
            height: "100vh",
          }}
        />
        <Header></Header>
        <Menu></Menu>
        <Hero></Hero>
        <Bens></Bens>
        <Solutions></Solutions>
        <BensBottom></BensBottom>
        <Footer></Footer>
      </div>
    </ContextProvider>
  );
}

export default App;
