import React, { useState } from "react";
import "./Bens.css";

export default function Bens() {
  const [showSocial, setShowSocial] = useState(false);

  return (
    <div className="bens">
      <div className="bens-container">
        <h2 className="bens-title">{"Conditions de participation : "}</h2>

        <div className="bens-list-text">
          <h3 className="bens-text">
            <span className="bens-text-step">{"1"}</span>
            <span className="bens-step-title">
              {"Abonnez-vous à l’une de nos pages @benssolutionsdechange sur : "}
              <a
                href="https://www.facebook.com/BenS.Change.Shop"
                target="_blank"
                rel="noopener noreferrer"
              >
                {"Facebook"}
              </a>
              ,{" "}
              <a
                href="https://www.instagram.com/bens.solutions.de.change"
                target="_blank"
                rel="noopener noreferrer"
              >
                {"Instagram"}
              </a>
              ,{" "}
              <a
                href="https://www.linkedin.com/company/ben-s-solutions-de-change"
                target="_blank"
                rel="noopener noreferrer"
              >
                {"LinkedIn"}
              </a>
              ,{" "}
              <a
                href="https://www.tiktok.com/@bens.solutions.de.change"
                target="_blank"
                rel="noopener noreferrer"
              >
                {"TikTok"}
              </a>
            </span>
          </h3>
          <h3 className="bens-text">
            <span className="bens-text-step">{"2"}</span>
            <span className="bens-step-title">
              {"Likez la publication sur nos réseaux et identifiez 2 amis"}
            </span>
          </h3>
          <h3 className="bens-text">
            <span className="bens-text-step">{"3"}</span>
            <span className="bens-step-title">
              <b style={{ color: "#B20000" }}>{"Bonus : "}</b>
              {"Partagez la publication en story"}
            </span>
          </h3>
        </div>
      </div>
      <div className="bens-container has-italic">
        <p className="bens-text-italic">
          {
            "Un Tirage au sort sera effectué chaque soir à 18 h. Nous informerons le/la gagnant(e) par message privé le lendemain, et nous lui demanderons de nous communiquer son adresse e-mail. Nous lui fournirons ensuite un voucher qu'elle devra présenter, accompagné d'une pièce d'identité, dans notre agence de Perly, afin de récupérer son lot à sa convenance."
          }
        </p>
        <p className="bens-text-italic">{"Bonne chance à tous !"}</p>
        <a
          href="/"
          target="_blank"
          rel="noopener noreferrer"
          className="btn-primary-cta"
          onClick={(e) => {
            e.preventDefault();
            setShowSocial(!showSocial);
          }}
        >
          {"Je tente ma chance"}
        </a>

        {showSocial ? (
          <div className="promo-social-links on-hero has-back">
            <a
              href="https://www.facebook.com/BenS.Change.Shop"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-icon"
            >
              <img
                src="https://bens-digital-change.com/images/icons/facebook.svg"
                alt="Facebook"
                width={48}
                height={48}
                loading="lazy"
              />
            </a>
            <a
              href="https://www.instagram.com/bens.solutions.de.change"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-icon"
            >
              <img
                src="https://bens-digital-change.com/images/icons/instagram.svg"
                alt="Instagram"
                width={48}
                height={48}
                loading="lazy"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/ben-s-solutions-de-change"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-icon"
            >
              <img
                src="https://bens-digital-change.com/images/icons/linkedin.svg"
                alt="Linkedin"
                width={48}
                height={48}
                loading="lazy"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UC3S8yVGmtgOStQzQrZh8fIg"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-icon"
            >
              <img
                src="https://bens-digital-change.com/images/icons/youtube.svg"
                alt="Youtube"
                width={48}
                height={48}
                loading="lazy"
              />
            </a>
            <a
              href="https://www.tiktok.com/@bens.solutions.de.change"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-icon"
            >
              <img
                src="https://bens-digital-change.com/images/icons/tiktok.svg"
                alt="TikTok"
                width={48}
                height={48}
                loading="lazy"
              />
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
}
