import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import "./Calculator.css";

function Calculator() {

  Axios.defaults.baseURL = process.env.REACT_APP_API;

  const [amountVal, setAmountVal] = useState("3000")
  const [stateReq, setStateReq] = useState({
    amount: "3000",
    currFrom: "CHF",
    currTo: "EUR",
    nbrDecimal: "10"
  })

  const [stateRes, setStateRes] = useState({
    isSuccess: false,
    amount_from: "",
    amount_to: "",
    datetime: "",
    rate: "",
    economy: "",
    economy_year: ""
  })

  const [state, setState] = useState({
    showSelect: false
  })

  const getData = async (val_amount, val_currFrom, val_currTo) => {
    try {

      const { data: res } = await Axios({
        method: 'GET',
        url: '/calculateCurr',
        params: {
          amount: val_amount,
          currFrom: val_currFrom,
          currTo: val_currTo,
          nbrDecimal: "10"
        }
      });
      setStateRes({
        ...stateRes,
        isSuccess: true,
        amount_from: res.amount_from,
        amount_to: res.amount_to,
        datetime: res.datetime,
        rate: res.rate,
        economy: res.economy,
        economy_year: res.economy_year
      });
      setStateReq({ ...stateReq, amount: res.amount_from, currFrom: val_currFrom, currTo: val_currTo })

    } catch (error) {
      // console.error(error)
    }
  };

  const fixedAmount = () => {
    setAmountVal(stateRes.amount_from);
  }
  const onGetData = (e) => {
    setAmountVal(e.target.value);
    if (e.target.value.length > 0 && parseFloat(e.target.value).toFixed() !== isNaN) {
      getData(e.target.value, stateReq.currFrom, stateReq.currTo);
    };
  }
  const getCurrFrom = (e) => {

    if (e.target.dataset.currency === "CHF") {
      setStateReq({ ...stateReq, currFrom: e.target.dataset.currency, currTo: "EUR" });
      setState({ ...state, showSelect: !state.showSelect });
      getData(stateReq.amount, e.target.dataset.currency, "EUR")
    }
    if (e.target.dataset.currency === "EUR") {
      setStateReq({ ...stateReq, currFrom: e.target.dataset.currency, currTo: "CHF" });
      setState({ ...state, showSelect: !state.showSelect });
      getData(stateReq.amount, e.target.dataset.currency, "CHF")
    }
  }

  const handleSelect = () => {
    setState({ ...state, showSelect: !state.showSelect })
  }

  useEffect(() => {
    let isStartFetch = true;

    if (isStartFetch) {
      const fetchData = async () => {
        try {
          const { data: res } = await Axios({
            method: 'GET',
            url: '/calculateCurr',
            params: stateReq

          });
          setStateRes({
            ...stateRes,
            isSuccess: true,
            amount_from: res.amount_from,
            amount_to: res.amount_to,
            datetime: res.datetime,
            rate: res.rate,
            economy: res.economy,
            economy_year: res.economy_year
          });
          setStateReq({ ...stateReq, amount: res.amount_from });
          setAmountVal(res.amount_from);
        } catch (error) {
          // console.error(error)
        }
      };

      fetchData();

      return () => { isStartFetch = false }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div id="calculator">
      <div className="calculator">
        <div className="calculator-header">
          <h3>Convertisseur</h3>
        </div>
        <div className="calculator-wrapper">

          <div className="calculator-input-primary">
            {/* Input */}
            <div className="calculator-input-box">

              <div className="bens-label">Vous envoyez</div>

              <input className="bens-input" type="text" name="bens-value-from" id="bens-value-from" value={amountVal} placeholder={3000} onChange={onGetData} onBlur={fixedAmount} />
            </div>
            {/* Select */}
            <div id="select-primary" className="bens-select-primary" data-currency={stateReq.currFrom} onClick={handleSelect}>
              <div className="bens-select-option" data-currency={stateReq.currFrom}>
                <div className="bens-select-option-name" data-currency={stateReq.currFrom}>{stateReq.currFrom}</div>
                <img className="bens-select-option-img" data-currency={stateReq.currFrom} alt={stateReq.currFrom} src={"https://bens-digital-change.com/images/v2/icon-flag-" + stateReq.currFrom.toLowerCase() + ".svg"} width={64} height={64} />
              </div>
            </div>
            {
              state.showSelect ?
                <div id="select-primary-list" className="bens-select-primary is-select-box">
                  <div className="bens-select-option" data-currency="CHF" onClick={getCurrFrom}>
                    <div className="bens-select-option-name" data-currency="CHF">CHF</div>
                    <img className="bens-select-option-img" data-currency="CHF" alt="CHF" src="https://bens-digital-change.com/images/v2/icon-flag-chf.svg" width={64} height={64} />
                  </div>
                  <div className="bens-select-option" data-currency="EUR" onClick={getCurrFrom}>
                    <div className="bens-select-option-name" data-currency="EUR">EUR</div>
                    <img className="bens-select-option-img" data-currency="EUR" alt="EUR" src="https://bens-digital-change.com/images/v2/icon-flag-eur.svg" width={64} height={64} />
                  </div>
                </div>
                :
                ""
            }
          </div>

          <div className="calculator-tax">
            <div id="cal-rate" className="tax-value">{stateRes.isSuccess ? stateRes.rate : "0"}</div>
            <div className="tax-label">
              <span className="tax-desc">* Taux Ben S. Digital Change</span>
              <span className="tax-info">Dernière actualisation</span>
              <span id="cal-time-01" className="tax-time">{stateRes.isSuccess ? stateRes.datetime : "-"}</span>
            </div>
          </div>

          <div className="calculator-fee">
            <div className="fee-value">0.00 CHF</div>
            <div className="fee-label">Frais appliqués</div>
          </div>

          <div className="calculator-input-secondary">
            {/* input */}
            <div className="calculator-input-box">
              <div className="bens-label">Vous recevez</div>
              <span className="bens-input" id="bens-value-to">{stateRes.isSuccess ? stateRes.amount_to : "0"}</span>
            </div>
            {/* select */}
            <div id="select-secondary" className="bens-select-secondary">
              <div className="bens-select-option">
                <div className="bens-select-option-name">{stateReq.currTo}</div>
                <img className="bens-select-option-img" alt={stateReq.currTo} src={"https://bens-digital-change.com/images/v2/icon-flag-" + stateReq.currTo.toLowerCase() + ".svg"} width={64} height={64} />
              </div>
            </div>
          </div>

          <div className="calculator-notices">
            <span>(*) à titre indicatif.</span>
            <span>Le taux de change appliqué sera celui du marché interbancaire à
              réception de vos fonds additionné de nos marges.</span>
          </div>
        </div>
        <div className="calculator-footer">
          <div className="footer-label">VOUS ECONOMISEZ {stateRes.isSuccess ? stateRes.economy : "-"} EUR</div>
          <div className="footer-value">Soit {stateRes.isSuccess ? stateRes.economy_year : "-"} <span>/an</span> EUR</div>
          <div className="footer-notice">Par rapport aux conditions bancaires</div>
          <div className="footer-notice">*taux non contractuel actualisé le {stateRes.isSuccess ? stateRes.datetime : "-"}</div>
        </div>
      </div>
    </div>
  )
}

export default Calculator