import React, { useEffect, useState } from "react";
import "./Solutions.css";
import CurrentTheme from "./CurrentTheme";
import config from "../config";
import rawData from "../data";

export default function Solutions() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const clonedData = [...rawData];

    const randomizedData = clonedData.sort(() => Math.random() - 0.5);

    setData(randomizedData);
  }, []);
  return (
    <div className="solutions">
      <div className="bens-container">
        <h3 className="solutions-title">{`${data?.length} lots à gagner`}</h3>
        <p className="solutions-desc">
          {"Les images ci-dessous sont placées dans un ordre totalement aléatoire"}
        </p>
        <p className="solutions-desc">
          {"Ne manquez pas la révélation du lot à remporter tous les matins dès 6h !"}
        </p>
        <p className="solutions-desc">{"Suivez nos réseaux sociaux pour en être informé."}</p>

        <div className="solutions-circles">
          {data?.map((e, i) => (
            <div className="circle-item" key={i.toString()}>
              <img
                src={`/img/circles/2023/${e?.img}?${config.version}`}
                alt={`${e?.title}`}
                width={240}
                height={240}
              />
              <div className="info">
                <h3>{e?.title}</h3>
                <h4>{`${e?.price} CHF`}</h4>
              </div>
              {e?.sponsor?.status ? (
                <img
                  className="sponsor"
                  src={`/img/${e?.sponsor?.logo}?${config.version}`}
                  alt={`${e?.sponsor?.text}`}
                  width={240}
                  height={240}
                />
              ) : null}
            </div>
          ))}
        </div>
      </div>
      <div
        className="solutions-back"
        style={{
          background:
            "linear-gradient(180deg, " +
            CurrentTheme(process.env.REACT_APP_THEME)["color"][0] +
            " 0%, " +
            CurrentTheme(process.env.REACT_APP_THEME)["color"][1] +
            " 100%)",
        }}
      ></div>
    </div>
  );
}
