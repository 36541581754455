export default function CurrentTheme(theme) {
  const themeColors = {
    "default": {
      "color": ["rgba(29,116,141,1)", "rgba(15,58,71,0)"],
      "cover": "/img/cover.webp"
    },
    "noel": {
      "color": ["rgba(56,93,64,1)", "rgba(1,201,45,0)"],
      "cover": "/img/cover_noel.webp"
    },
    "halloween": {
      "color": ["rgba(100,35,12,1)", "rgba(120,44,16,0)"],
      "cover": "/img/cover_halloween.webp"
    },
    "easter": {
      "color": ["rgba(109,197,58,1)", "rgba(36,255,27,0)"],
      "cover": "/img/cover_easter.webp"
    },
    "summer": {
      "color": ["rgba(255,115,27,1)", "rgba(255,185,27,0)"],
      "cover": "/img/cover_summer.webp"
    },
    "calendrierdelavent": {
      "color": ["rgba(56,93,64,1)", "rgba(1,201,45,0)"],
      "cover": "/img/cover_calendrierdelavent.webp"
    }
  }
  if (theme === "default") {
    return themeColors.default
  }
  if (theme === "noel") {
    return themeColors.noel
  }
  if (theme === "halloween") {
    return themeColors.halloween
  }
  if (theme === "easter") {
    return themeColors.easter
  }
  if (theme === "summer") {
    return themeColors.summer
  }
  if (theme === "calendrierdelavent") {
    return themeColors.calendrierdelavent
  }
}